
.mr-10{
    margin-right: 10px;
}
.mr-20{
    margin-right: 20px;
}
.pr-5{
    padding-right: 5px;
}
.pl-5{
    padding-left: 10px !important;
}
