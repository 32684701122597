.markdown-container{
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  textarea {
    background: #eff1f5;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1),0 1px;
    outline: none;
    border-radius: 5px;
    padding: 10px;
  }
  .React-Markdown{
    padding: 20px;
  }
}