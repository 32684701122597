
.faq-card-wrapper{
  padding: 26px 0;
  .faq-card{
    box-shadow: 0 0 5px black;
    border-radius: 10px;
    padding: 10px;
    .content{
      padding: 5px 10px;
    }
    .mt-16{
      margin-top: 16px;
    }
    .mt-45{
      margin-top: 45px;
    }
    .mt-10{
      margin-top: 10px;
    }
    .img-fluid{
      padding: 0 13px 0 7px;
    }
    .mt-19{
      margin-top: 19px;
    }
    .mtop-4{
      margin-top: 4px;
    }
    .pic-title{
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 1.8;
      color: #6A726B;
      padding-left: 10px;
    }
    .pic-comment{
      font-family: 'verdana';
      font-style: normal;
      font-weight: normal;
      line-height: 1.8;
      font-size: 16px;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 10px;
      color: #6A726B;
    }
    .faq-title-wrapper{
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 34px;
      line-height: 46px;
      color: #6A726B;
      padding-left: 6px;
    }
    .faq-btn-container{
      display: flex;
      justify-content: flex-end;
      padding-right:9px;
      .faq-btn-wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 50%;
        .toggle-btn{
          width: 33px;
          height: 40px;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 8px;
          background: rgba(48, 48, 48, 0.8);
          border-radius: 5px;
          transition: all 0.2s;
          margin-right: 17px;

        }
        .toggle-btn-rev{
          width: 33px;
          height: 40px;
          background: rgba(48, 48, 48, 0.8);
          border-radius: 5px;
          transition: all 0.2s;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 8px;
          transform: rotate(-180deg);
          margin-right: 17px;
        }
        .publish-date{
          font-family: Nunito Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 19px;
          color: #6A726B;
          margin-right:40px;

        }
        .edit-faq-btn{
          cursor: pointer;
          width: 42px;
          height: 42px;
          border-radius: 50%;
          background: rgba(48, 48, 48, 0.7);
          display: flex;
          justify-content: center;
          align-items: center;
          @media(max-width: 992px) {
            margin-top: 10px;
          }
        }
      }
    }
    .article-body-wrapper{
      font-family: 'verdana';
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      // padding-top: 10px;
      line-height: 1.8;
      padding-bottom:10px;
      padding-left: 6px;
      color: #6A726B;
    }
  }
  .video-wrapper{
    padding-left: 8px;
    padding-right: 8px;
    width: 100%;


  }
}
.w-100{
  width: 100%!important;
  overflow: hidden;
}
.vjs-tech{
  width: 100%!important;
}
.collapse{
   width: 100%!important;
}
.share-button {
  margin: 2px;
}
.share-body {
  margin-left: 15px;
}
.pr-10{
  padding-right: 10px;
}
.pr-20{
  padding-right: 20px;
}
.pr-30{
  padding-right: 30px;
}
.pr-40{
  padding-right: 40px;
}
.mr-10{
  margin-right: 10px;
}
.mr-20{
  margin-right: 20px;
}
.mr-30{
  margin-right: 30px;
}
.mr-40{
  margin-right: 40px;
}
.article-image{
  width: 100%;
  height: 100%;
  // object-fit: cover;
  border-radius:20px;

}
.article-video {
  width: 100%;
  height: 100%;
  border-radius:20px;
  overflow: hidden;
}