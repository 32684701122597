.add-faq-wrapper{
  width: inherit;
  margin-bottom: 40px;
  .Faq-form{
    .save-cancel-wrapper{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 30px;
      color: #FFFFFF;
      .cancel-btn{
        cursor: pointer;
        width: 74px;
        height: 28px;
        background: #A14D4D;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .publish-btn{
        cursor: pointer;
        width: 183px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #59A14D;
        border-radius: 5px;
      }
    }
    .upload-media-btn{
      background: #4D97A1;
      border-radius: 5px;
      height: 28px;
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      margin-top: 13px;
      color: #FFFFFF;
      width: 138px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .input-video-url{
      padding: 5px 15px;
      width: 100%;
      background: #A2AAAB;
      border-radius: 5px;
      input{
        width: 100%;
        background: transparent;
        outline: none;
        border: none;
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        color: #FFFFFF;
      }
      .select{
        border: none;
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 19px;
        background: #A2AAAB;
      }

      input::placeholder{
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        color: #FFFFFF;
      }
    }
    .input-wrapper{
      height: 51px;
      width: 100%;
      margin: 10px 0;
      background: #FFFFFF;
      border: 1px solid #000000;
      box-sizing: border-box;
      .input{
        border: none;
        outline: none;
        background: transparent;
        width: 100%;
        height: 51px;
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 33px;
        color: #6A726B;
      }
      .input::placeholder{
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 33px;
        color: #6A726B;
        padding: 10px;
      }
    }
    .mt{
      margin-top: 20px;
    }
    .p-10{
      padding: 10px;
    }
    .p-15{
      padding: 15px;
    }
    .p-20{
      padding: 20px;
    }
    .p-25{
      padding: 25px;
    }
    .mt-10{
      margin-top: 10px;
    }
    .m-10{
      margin: 10px;
    }
    .m-15{
      margin: 15px;
    }
    .m-20{
      margin: 20px;
    }
    .mt-10{
      margin-top: 10px;
    }
    .mt-15{
      margin-top: 15px;
    }
    .mt-20{
      margin-top: 20px;
    }
    .mb-10{
      margin-bottom: 10px;
    }
    .mb-15{
      margin-bottom: 15px;
    }
    .mb-20{
      margin-bottom: 20px;
    }
    .mb-25{
      margin-bottom: 25px;
    }
    .mb-35{
      margin-bottom: 35px;
    }
    .mb-40{
      margin-bottom: 40px;
    }
    .mb-50{
      margin-bottom: 50px;
    }
    .mb-60{
      margin-bottom: 60px;
    }
    .mb-70{
      margin-bottom: 70px;
    }
    .font-14{
      font-size: 14px;
    }
    .font-18{
      font-size: 25px;
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: normal;
      line-height: 19px;
    };
    .mx-40 {
      margin-left: 40px;
      margin-right: 40px;
    }
    .border-1{
      border: 1px solid #000000;
      
    }
  }
}
