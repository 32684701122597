.home-page-wrapper{
  margin-top: 50px;
  .keep-center{
    display: flex;
    justify-content: center;

  }
  .mt-30{
    margin-top: 30px;
  }
  .mb-30{
    margin-bottom: 30px;
  }
  .border-b-custom{
    height: 1px;
    background: #CACACB;
    transform: matrix(1, 0, 0, -1, 0, 0);
  }
  .add-new-article-btn-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    .add-new-article-btn{
      background: #303030;
      box-shadow: 0px 10px 20px rgba(48, 48, 48, 0.25);
      border-radius: 8px;
      width: 335px;
      padding: 16px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 40px;
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
      text-align: center;
      cursor: pointer;
      color: #FFFFFF;
      @media(max-width: 340px) {
        width: 95%;
      }
    }

  }
  .faq-wrapper{
    padding-right: 25px;
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .faq{
      width: 100px;
      height: 49px;
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 36px;
      //line-height: 49px;
      color: rgba(0, 0, 0, 0.48);
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      .faq-span{
        padding-left: 24px;
      }
    }
    .first-input-wrapper {
      margin-top: 35px;
      margin-right: 35px;
      padding-left: 24px;
      .input-holder {
        width: 100%;
        height: 40px;
        margin-top: 8px;
        padding-left: 8px;
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 25px;
        border: none;
        color: rgba(0, 0, 0, 0.53);
        border-radius: 5px;
        background: rgba(196, 196, 196, 0.25)
      }
    }
  }
  .sort-btn{
    width: 134px;
    height: 26px;

    background: rgba(241, 239, 239, 0.75);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Assistant;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: rgba(0, 0, 0, 0.47);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 18px;
    .icon{
      background: rgba(196, 196, 196, 0.47);
      border-radius: 5px;
      margin-left: 7px;
      transition: all 0.2s;
    }
    .icon-rev{
      transform: rotate(-180deg);
    }
  }
}
